import { MeteringResourceServiceModel } from '../model/metering-resource-service.model';
import { MeteringResourceListModel } from '../model/metering-resource-list.model';
import { Development } from 'app/main/configurations/development/development.model';
import { MeteringListModel } from '../model/metering-list.model';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "app/environment/environment";
import { Observable } from "rxjs";
import { MeteringServiceListModel } from '../model/metering-service-list.model';
import MeteringCreate from '../model/metering-create.model';

@Injectable()
export class PlanningMeteringService {
    apiUrl = environment.apiUrl + "/engineering/metering";

    constructor(private _httpClient: HttpClient) { }

    getMeteringList(development: Development): Observable<MeteringListModel[]> {
        const url = `${this.apiUrl}/list`;

        return this._httpClient.post<MeteringListModel[]>(url, { development: development });
    }

    getMeteringResourceList(developmentId: String, measureKey: String): Observable<MeteringResourceListModel[]> {
        const url = `${this.apiUrl}/resource/list/${developmentId}/${measureKey}`;

        return this._httpClient.get<MeteringResourceListModel[]>(url);
    }

    getMeteringServiceList(measureKey: String, resources: MeteringResourceServiceModel[], engineeringServices: MeteringResourceServiceModel[]): Observable<MeteringServiceListModel[]> {
        const url = `${this.apiUrl}/service/list`;

        let request = {
            engineeringServiceMeasureKey: measureKey,
            resources: resources,
            engineeringServices: engineeringServices
        };

        return this._httpClient.post<MeteringServiceListModel[]>(url, request);
    }

    createMetering(metering: MeteringCreate): Observable<any> {
        const url = `${this.apiUrl}/create`;

        return this._httpClient.post<any>(url, metering);
    }

}
